import React from "react";
import PropTypes from "prop-types";
import Image from "gatsby-image";
import { get } from "lodash";
import { graphql } from "gatsby";
import App from "../components/App";
import SEO from "../components/SEO";
import FeaturedTestimonial from "../components/FeaturedTestimonial";
import CallToAction from "../components/CallToAction";
import Slider from "../components/Slider";
import Wrapper from "../components/Wrapper";
import Container from "../components/Container";
import Grid, { ColouredHalf } from "../components/Grid";
import CardLink from "../components/CardLink";
import HeroInterior from "../components/HeroInterior";
import RichText from "../components/RichText";
import ButtonLink from "../components/Button";
import CardResource from "../components/CardResource";

export default function HomePage({ data }) {
  const { contentfulHomepage } = data;
  const {
    testimonial,
    callToAction,
    cards,
    header,
    body,
    blocks,
    cases,
  } = contentfulHomepage;

  return (
    <App>
      <SEO {...contentfulHomepage} />
      <HeroInterior {...header} subtitle={get(header, "subtitle.subtitle")} />
      <Slider />
      <Wrapper variant="muted">
        <Container small>
          <RichText body={body} center />
        </Container>
        <Container large>
          <Grid>
            {cards.map((card, i) => (
              <CardLink {...card} small num={i} key={card.contentful_id} />
            ))}
          </Grid>
        </Container>
      </Wrapper>
      <Container large>
        <Grid>
          <ColouredHalf>
            {cases.map((ob) => (
              <CardResource
                full
                {...ob}
                key={ob.contentful_id}
                url={`/case-studies/${ob.slug}`}
              />
            ))}
          </ColouredHalf>
          {blocks.map(
            (block, i) =>
              i === 0 && (
                <ColouredHalf>
                  <h2>{block.title}</h2>
                  <RichText body={block.description} />
                  <Image
                    {...block.graphic}
                    style={{
                      margin: "2rem 0",
                      width: 300,
                    }}
                  />
                  <ButtonLink to={block.buttonDestination}>
                    {block.buttonLabel}
                  </ButtonLink>
                </ColouredHalf>
              )
          )}
        </Grid>
      </Container>
      <Wrapper variant="darkMuted">
        <FeaturedTestimonial {...testimonial} />
      </Wrapper>
      <CallToAction {...callToAction} />
    </App>
  );
}

HomePage.propTypes = {
  data: PropTypes.shape({
    contentfulHomepage: PropTypes.object,
  }).isRequired,
};

export const query = graphql`
  fragment TestimonialDetail on ContentfulTestimonal {
    contentful_id
    name
    company
    title
    videoUrl
    blockquote {
      blockquote
    }
    link {
      slug
    }
    logo {
      alt: title
      fluid(maxWidth: 200, quality: 100) {
        ...GatsbyContentfulFluid
      }
    }
  }

  fragment CardDetail on ContentfulCard {
    title
    contentful_id
    icon
    graphic {
      alt: title
      fluid(maxWidth: 600, quality: 100) {
        ...GatsbyContentfulFluid
      }
    }
    buttonLabel
    buttonDestination
    description {
      childContentfulRichText {
        html
      }
    }
  }

  fragment CallToActionDetail on ContentfulCallToAction {
    contentful_id
    title
    buttonLabel
    buttonDestination
    body {
      childContentfulRichText {
        html
      }
    }
    cards {
      ...CardDetail
    }
  }

  fragment HeroDetail on ContentfulHero {
    title
    videoUrl
    version: colour
    finePrint
    subtitle {
      subtitle
    }
    buttonLabel
    buttonDestination
    picture {
      alt: title
      fluid(maxWidth: 1200, quality: 100) {
        ...GatsbyContentfulFluid
      }
    }
  }

  query {
    contentfulHomepage {
      title
      description {
        description
      }
      body {
        childContentfulRichText {
          html
        }
      }
      header {
        ...HeroDetail
      }
      cards {
        ...CardDetail
      }
      blocks {
        ...CardDetail
      }
      testimonial {
        ...TestimonialDetail
      }
      callToAction {
        ...CallToActionDetail
      }
      cases {
        title
        slug
        internal {
          type
        }
        logo: companyLogo {
          alt: title
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`;
