import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { HeroSubTitle } from "./HeroInterior";
import Container from "./Container";
import Grid from "./Grid";
import Card from "./Card";
import ButtonLink from "./Button";
import globalProps from "../props";
import theme from "../theme";

const CallToActionContent = styled.aside`
  margin: 0 auto;
  text-align: center;
  width: 50%;

  @media (max-width: 526px) {
    width: 90%;
  }
`;

export const CallToActionWrapper = styled.div`
  background-image: linear-gradient(to right, ${theme.dark}, #182238);
  background-size: cover;
  color: ${theme.contrast};
  padding: 4vh 6vw 8vh;
  position: relative;
  z-index: 1;
  transition: background 1s;

  @media (max-width: 526px) {
    padding: 4vh 3vw;
  }
`;

const CallToActionHeading = styled.h3`
  color: inherit;
  font-size: 1.689em;
  margin: 1rem 0;
`;

export default function CallToAction({
  title,
  body,
  buttonLabel,
  buttonDestination,
  cards,
}) {
  return (
    <CallToActionWrapper>
      {cards && (
        <Container>
          <Grid>
            {cards.map((card, i) => (
              <Card {...card} num={i} key={card.contentful_id} />
            ))}
          </Grid>
        </Container>
      )}
      <CallToActionContent>
        <CallToActionHeading>{title}</CallToActionHeading>
        {body && (
          <HeroSubTitle
            style={{ margin: "1rem auto 2rem" }}
            dangerouslySetInnerHTML={{
              __html: body.childContentfulRichText.html,
            }}
          />
        )}
        <ButtonLink to={buttonDestination} fill>
          {buttonLabel}
        </ButtonLink>
      </CallToActionContent>
    </CallToActionWrapper>
  );
}

CallToAction.propTypes = {
  title: PropTypes.string.isRequired,
  body: globalProps.body.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  buttonDestination: PropTypes.string.isRequired,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      contentful_id: PropTypes.string,
    })
  ).isRequired,
};
