import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Image from "gatsby-image";
import { GoQuote, GoLink } from "react-icons/go";
import * as Icons from "react-icons/fi";
import { graphql, StaticQuery, Link } from "gatsby";
import { FirstSentenceOfBlockQuote, Citation, Title } from "./Testimonial";
import Grid from "./Grid";
import Container from "./Container";
import { CardBody } from "./CardLink";
import theme from "../theme";
import globalProps from "../props";
import EmbeddedVideo, { getVideoHostLink } from "./EmbeddedVideo";

const CardWrapper = styled.div`
  height: 275px;
  margin: 1vh 0.5vw;
  width: 300px;

  @media (max-width: 1125px) {
    height: auto;
    width: 100%;
  }
`;

const Logo = styled.img`
  height: 65px;
`;

const query = graphql`
  query {
    contentfulHomepage {
      capterraReviewCount
      capterraRating
    }
  }
`;

const QuoteBlock = styled.div`
  flex: 1;
  margin: 2rem 6rem;
  min-width: 300px;

  @media (max-width: 500px) {
    min-width: 65%;
  }
`;

function Statistics() {
  return (
    <CardWrapper>
      <StaticQuery
        query={query}
        render={({ contentfulHomepage }) => {
          const { capterraRating, capterraReviewCount } = contentfulHomepage;
          const stars = [];
          for (let i = 0; i < Math.round(capterraRating); i++) {
            stars.push(
              <Icons.FiStar
                size="22"
                style={{
                  color: "#f5f516",
                  fill: "#f5f516",
                }}
              />
            );
          }
          return (
            <a
              href="https://www.capterra.com/p/142029/Word-LX/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Go to Capterra Reviews"
              style={{ fontSize: "inherit" }}
            >
              <CardBody>
                <Logo src="/capterra.png" alt="Capterra Logo" />

                <h3>
                  {`${capterraRating}/5 from ${capterraReviewCount} Reviews on Capterra`}
                </h3>
                <p>{stars.map((star) => star)}</p>
              </CardBody>
            </a>
          );
        }}
      />
    </CardWrapper>
  );
}

function Awards() {
  return (
    <CardWrapper>
      <Link to="/word-lx">
        <CardBody>
          <Logo src="/techno-2020.png" alt="Newswire Top 10" />
          <h3>TechnoLawyer Top Product Award 2020</h3>
          <small>Learn more about our award-winning Word LX™</small>
        </CardBody>
      </Link>
    </CardWrapper>
  );
}

function Word() {
  return (
    <CardWrapper>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://images.ctfassets.net/enjae5x2a00z/3tK2LTzbrnLfNTlDnwRAot/f9bd27df474c27096274aa3a5d616c9c/Learnpaper_-_Template_Management_System__1_.pdf"
      >
        <CardBody>
          <img
            style={{ height: "calc(100% - 14px)" }}
            src="/learnpaper-thumb.png"
            alt="Technolawyer Learnpaper"
          />
          <small style={{ display: "block" }}>
            <GoLink /> Click to download
          </small>
        </CardBody>
      </a>
    </CardWrapper>
  );
}

export default function FeaturedTestimonial({
  name,
  title,
  blockquote,
  logo,
  link,
  isWord,
  videoUrl,
}) {
  let firstPeriod;
  let firstSentence;
  let restOfParagraph;

  const embed = getVideoHostLink(videoUrl);

  if (blockquote) {
    const { blockquote: quote } = blockquote;
    firstPeriod = quote.indexOf(".") + 1;
    firstSentence = quote.substring(0, firstPeriod);
    restOfParagraph = quote.substring(firstPeriod);
  }

  return (
    <Container large>
      <Grid justify="center" align={"center"}>
        {isWord ? <Word /> : <Awards />}
        {embed && (
          <div style={{ flex: 1, minWidth: 350 }}>
            <EmbeddedVideo src={embed} />
          </div>
        )}
        <Statistics />
        {!embed && (
          <QuoteBlock>
            <FirstSentenceOfBlockQuote>
              <GoQuote
                size="36"
                color={theme.primary}
                style={{
                  position: "absolute",
                  top: "-1rem",
                  right: "calc(100% + 1rem)",
                }}
              />
              {firstSentence}
            </FirstSentenceOfBlockQuote>
            {restOfParagraph && <p>{restOfParagraph}</p>}
            <Grid align="center">
              {logo && (
                <Image
                  {...logo}
                  imgStyle={{
                    objectFit: "contain",
                    mixBlendMode: "multiply",
                  }}
                  style={{
                    height: 85,
                    marginTop: "1rem",
                    marginRight: "2rem",
                    width: 120,
                  }}
                />
              )}
              <div>
                <Citation>{name}</Citation>
                <Title>{title}</Title>
                {link && (
                  <Link
                    to={`/case-studies/${link.slug}`}
                    style={{ textDecoration: "underline" }}
                  >
                    Read the Full Case Study
                  </Link>
                )}
              </div>
            </Grid>
          </QuoteBlock>
        )}
      </Grid>
    </Container>
  );
}

FeaturedTestimonial.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  blockquote: PropTypes.shape({
    blockquote: PropTypes.string,
  }).isRequired,
  link: PropTypes.shape({
    slug: PropTypes.string,
  }).isRequired,
  logo: globalProps.image.isRequired,
  isWord: PropTypes.bool,
  videoUrl: PropTypes.string,
};

FeaturedTestimonial.defaultProps = {
  isWord: false,
};
