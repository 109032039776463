import React from 'react';
import PropTypes from 'prop-types';
import { GoQuote } from 'react-icons/go';
import styled from 'styled-components';
import Container from './Container';
import Wrapper from './Wrapper';
import theme from '../theme';

const TestimonialWrapper = styled.blockquote`
  text-align: center;
  margin: 0;

  p { 
    color: inherit; 
  }
`;

export const Citation = styled.cite`
  display: block;
  font-weight: 800;
  margin-top: 2rem;
  font-style: normal;
`;

export const Title = styled.small`
  display: block;
`;

export const FirstSentenceOfBlockQuote = styled.p`
  font-size: 1.507em;
  font-weight: 300;
  position: relative;

  @media (max-width: 769px){
    font-size: 1.3em;
  }
`;

/**
 * @DESCRIPTION
 * Simple blockquote component.
 * Fullspan with emphasis on the first sentence.
 */

export default function Testimonial({
  name,
  title,
  blockquote,
}) {
  const { blockquote: quote } = blockquote;
  const firstPeriod = quote.indexOf('.') + 1;
  const firstSentence = quote.substring(0, firstPeriod);
  const restOfParagraph = quote.substring(firstPeriod);

  return (
    <Wrapper
      variant="darkMuted"
      style={{
        paddingTop: 0,
        paddingBottom: '1rem',
      }}
    >
      <TestimonialWrapper>
        <Container small>
          <FirstSentenceOfBlockQuote>
            <GoQuote
              size="36"
              color={theme.primary}
              style={{
                position: 'absolute',
                top: '-1rem',
                right: '100%',
              }}
            />
            {firstSentence}
          </FirstSentenceOfBlockQuote>
          <p>{restOfParagraph}</p>
          <Citation>
            {name}
          </Citation>
          <Title>
            {title}
          </Title>
        </Container>
      </TestimonialWrapper>
    </Wrapper>
  );
}

Testimonial.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  blockquote: PropTypes.shape({
    blockquote: PropTypes.string,
  }),
};

Testimonial.defaultProps = {
  blockquote: { blockquote: '' },
};
