import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import Carousel from 'react-slick';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import styled from 'styled-components';
import Container from './Container';

const SliderLogo = styled(Image)`
  display: inline-block;
  filter: grayscale(1);
  margin: 3vh .75rem;
  width: 220px !important;

  @media(max-width: 768px){
    width: 120px !important;
  }
`;

const query = graphql`
  query {
    contentfulHomepage{ 
      gallery {
        media {
          alt: title
          contentful_id
          fluid(maxWidth: 200){
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`;

const NextButton = styled.button`
  background: transparent;
  border: 0;
  cursor: pointer;
  left: calc(100% + 1rem);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

const PrevButton = styled(NextButton)`
  right: calc(100% + 1rem);
  left: auto;
`;

function Prev({ onClick }) {
  return (
    <PrevButton onClick={onClick}>
      <FiChevronLeft size="22" />
    </PrevButton>
  );
}

function Next({ onClick }) {
  return (
    <NextButton onClick={onClick}>
      <FiChevronRight size="22" />
    </NextButton>
  );
}

export default function Slider() {
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    accessibility: true,
    swipeToSlide: true,
    speed: 1000,
    infinite: true,
    prevArrow: <Prev />,
    nextArrow: <Next />,
  };

  return (
    <Container large>
      <StaticQuery
        query={query}
        render={({ contentfulHomepage }) => {
          const { media } = contentfulHomepage.gallery;
          return (
            <Carousel {...settings}>
              {media.map(pic => (
                <SliderLogo
                  {...pic}
                  key={pic.contentful_id}
                  imgStyle={{
                    objectFit: 'contain',
                  }}
                />
              ))}
            </Carousel>
          );
        }}
      />
    </Container>
  );
}
